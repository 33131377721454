<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Sitios</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Tif</li>
                  <li class="breadcrumb-item active">General</li>
                  <li class="breadcrumb-item active">Basico</li>
                  <li class="breadcrumb-item active">{{ accion }} Sitio</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2 border-0">
                <div class="col-6 pt-2 pb-2">
                  <h5 class="mb-0">Formulario {{ accion }} Sitio</h5>
                </div>
              </div>
              <div class="card-body p-0">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <li class="nav-item">
                    <a
                      class="nav-link active"
                      id="tab-Principal"
                      data-toggle="tab"
                      href="#Principal"
                      >Principal</a
                    >
                  </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                  <div class="tab-pane fade active show" id="Principal">
                    <div class="card-body">
                      <div class="row">
                        <div class="form-group col-md-4">
                          <label>Nombre</label>
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            v-model="form.nombre"
                            :class="
                              $v.form.nombre.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          />
                        </div>
                        <div class="form-group col-md-4">
                          <label>Capacidad Maxima (Barriles)</label>
                          <input
                            type="number"
                            class="form-control form-control-sm"
                            v-model="form.capacidad_max"
                            :class="
                              $v.form.capacidad_max.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          />
                        </div>
                        <div class="form-group col-md-4">
                          <label>Direccion</label>
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            v-model="form.direccion"
                            :class="
                              $v.form.direccion.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          />
                        </div>
                        <div class="form-group col-md-4">
                          <label>Telefono</label>
                          <input
                            type="number"
                            class="form-control form-control-sm"
                            v-model="form.telefono"
                            :class="
                              $v.form.telefono.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          />
                        </div>
                        <div class="form-group col-md-4">
                          <label>Celular</label>
                          <input
                            type="number"
                            class="form-control form-control-sm"
                            v-model="form.celular"
                            :class="
                              $v.form.celular.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          />
                        </div>
                        <div class="form-group col-md-4">
                          <label>Nombre del Contacto</label>
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            v-model="form.nombre_contacto"
                            :class="
                              $v.form.nombre_contacto.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          />
                        </div>
                        <div class="form-group col-md-4">
                          <label>Tipo de sitio</label>
                          <v-select
                            :class="[
                              $v.form.tipo_sitio.$invalid
                                ? 'is-invalid'
                                : 'is-valid',
                              $store.getters.getDarkMode ? 'dark-vselect' : '',
                            ]"
                            v-model="form.tipo_sitio"
                            placeholder="Tipo Sitio"
                            label="descripcion"
                            :options="listasForms.tipoSitio"
                            class="form-control form-control-sm p-0"
                          ></v-select>
                        </div>
                        <div class="form-group col-md-4">
                          <label>Geocerca</label>
                          <v-select
                            :class="[
                              $v.form.geocerca_zona_id.$invalid
                                ? 'is-invalid'
                                : 'is-valid',
                              $store.getters.getDarkMode ? 'dark-vselect' : '',
                            ]"
                            v-model="form.geocerca_zona_id"
                            placeholder="Geocerca Zona"
                            label="nombre"
                            :options="listasForms.zona"
                            class="form-control form-control-sm p-0"
                          ></v-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="row">
                  <button
                    class="btn bg-primary col-md-1"
                    v-show="!$v.form.$invalid"
                    v-if="$store.getters.can('tif.sitios.create')"
                    @click="save()"
                  >
                    <i class="fas fa-paper-plane"></i><br />Guardar
                  </button>
                  <button class="btn bg-secondary col-md-1" @click="back()">
                    <i class="fas fa-reply"></i><br />Volver
                  </button>
                </div>
              </div>
            </div>
            <div class="row" v-if="$route.params.sitio">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="accordion" id="accordionExample">
                  <div class="card bg-light border-danger">
                    <div class="card-header" id="headingOne">
                      <h5 class="mb-0">
                        <button
                          class="btn btn-primary bg-navy"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapse1"
                          aria-expanded="false"
                          aria-controls="collapse1"
                        >
                          Operaciones del Sitio
                        </button>
                      </h5>
                    </div>
                    <div
                      id="collapse1"
                      class="collapse"
                      aria-labelledby="headingOne"
                      data-parent="#accordionExample"
                    >
                      <div class="card-body">
                        <form>
                          <div class="row">
                            <div class="col-md-2">
                              <label>Bloque:</label>
                              <v-select
                                :class="
                                  $store.getters.getDarkMode
                                    ? 'dark-vselect'
                                    : ''
                                "
                                v-model="operacion.bloque"
                                placeholder="Bloque"
                                label="nombre"
                                :options="listasForms.bloques"
                                :filterable="false"
                                @search="buscarBloques"
                                @input="getOperacionesSitio"
                                class="form-control form-control-sm p-0"
                              ></v-select>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group">
                                <label for="operacion_sitio">Operaciones</label>
                                <select
                                  class="form-control form-control-sm"
                                  v-model="operacion.tif_operacion_id"
                                  @change="getpro()"
                                  :disabled="!operacion.bloque"
                                >
                                  <option value="">Seleccione...</option>
                                  <option
                                    v-for="operacion in listasForms.operacionesSitio"
                                    :key="operacion.id"
                                    :value="operacion.id"
                                  >
                                    {{ operacion.Noperacion }}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div class="col-md-3">
                              <label for="operacion_sitio">Producto</label>
                              <select
                                class="form-control form-control-sm"
                                v-model="operacion.tif_producto_id"
                                :disabled="!operacion.tif_operacion_id"
                              >
                                <option value="">Seleccione...</option>
                                <option
                                  v-for="producto in listasForms.productos
                                    .productos"
                                  :key="producto.id"
                                  :value="producto.id"
                                >
                                  {{ producto.nombre }}
                                </option>
                              </select>
                            </div>
                            <div class="form-group col-md-2">
                              <label>Tipo de Operación</label>
                              <select
                                class="form-control form-control-sm"
                                v-model="operacion.tipo_operacion"
                                :disabled="!operacion.tif_producto_id"
                              >
                                <option value="">Seleccione...</option>
                                <option
                                  v-for="tipoOp in listasForms.tipoOperacion"
                                  :key="tipoOp.numeracion"
                                  :value="tipoOp.numeracion"
                                >
                                  {{ tipoOp.descripcion }}
                                </option>
                              </select>
                            </div>
                            <div class="col-md-2">
                              <div class="btn-group float-right">
                                <button
                                  type="button"
                                  class="btn btn-primary"
                                  style="margin-top: 25px"
                                  v-show="
                                    operacion.tif_operacion_id &&
                                      operacion.tif_producto_id &&
                                      operacion.tipo_operacion &&
                                      operacion.bloque
                                  "
                                  v-if="$store.getters.can('tif.sitios.edit')"
                                  @click="validarOperacion()"
                                >
                                  <i class="fas fa-paper-plane"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                        <!-- TABLA OPERACION -->
                        <div>
                          <table class="table">
                            <thead class="thead-dark">
                              <tr>
                                <th>Operacion</th>
                                <th>Producto</th>
                                <th>Tipo de Operación</th>
                                <th>Bloque</th>
                                <th>Accion</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="item in listasForms.operations"
                                :key="item.id"
                              >
                                <td>
                                  <b>{{ item.Noperacion }}</b>
                                </td>
                                <td>
                                  <div
                                    style="height: 30px"
                                    v-for="p in item.producto"
                                    :key="p.id"
                                  >
                                    {{ p.producto.nombre }}
                                  </div>
                                </td>
                                <td>
                                  <div
                                    style="height: 30px"
                                    v-for="p in item.producto"
                                    :key="p.id"
                                  >
                                    {{ p.TipoOperacion }}
                                  </div>
                                </td>
                                <td>
                                  <b>{{ item.bloque.nombre }}</b>
                                </td>
                                <td>
                                  <div
                                    style="height: 30px"
                                    v-for="p in item.producto"
                                    :key="p.id"
                                  >
                                    <button
                                      type="button"
                                      class="btn btn-xs btn-danger"
                                      v-on:click="destroy(p.id)"
                                    >
                                      <i class="fas fa-trash-alt"></i>
                                    </button>
                                  </div>

                                  <!-- v-if="
                                      $store.getters.can('tif.sitios.delete')
                                    " -->
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import { required } from "vuelidate/lib/validators";
import Loading from "../../../../components/Loading";

export default {
  name: "TifSitiosForm",
  components: {
    Loading,
    vSelect,
  },
  data() {
    return {
      cargando: true,
      nominacion: {},
      validar: 0,
      permite_editar: true,
      form: {
        id: null,
        nombre: null,
        capacidad_max: null,
        telefono: null,
        direccion: null,
        celular: null,
        nombre_contacto: null,
        tipo_sitio: null,
        geocerca_zona_id: null,
      },
      accion: "",
      metodo: "",
      listasForms: {
        tipoSitio: [],
        estados: [],
        zona: [],
        operacionesSitio: [],
        tipoOperacion: [],
        operations: {},
        productos: [],
        bloques: [],
      },
      operacion: {
        tif_operacion_id: null,
        tif_producto_id: null,
        tif_sitio_id: null,
        tipo_operacion: null,
        bloque: null,
      },

      tipo_sitio: null,
      geocerca_zona_id: null,
    };
  },
  validations() {
    return {
      form: {
        nombre: {
          required,
        },
        capacidad_max: {
          required,
        },
        direccion: {
          required,
        },
        telefono: {
          required,
        },
        celular: {
          required,
        },
        nombre_contacto: {
          required,
        },
        tipo_sitio: {
          required,
        },
        geocerca_zona_id: {
          required,
        },
      },
    };
  },

  methods: {
    getIndex() {
      this.cargando = true;
      if (this.$route.params.accion) {
        this.accion = this.$route.params.accion;
      }

      if (this.accion == "Editar") {
        this.metodo = "PUT";
        if (this.tipo_sitio || this.geocerca_zona_id) {
          this.form = {
            id: this.$route.params.sitio.id,
            nombre: this.$route.params.sitio.nombre,
            capacidad_max: this.$route.params.sitio.capacidad_max,
            telefono: this.$route.params.sitio.telefono,
            direccion: this.$route.params.sitio.direccion,
            celular: this.$route.params.sitio.celular,
            nombre_contacto: this.$route.params.sitio.nombre_contacto,
            tipo_sitio: this.tipo_sitio,
            geocerca_zona_id: this.geocerca_zona_id,
          };
        } else {
          this.form = {
            id: this.$route.params.sitio.id,
            nombre: this.$route.params.sitio.nombre,
            capacidad_max: this.$route.params.sitio.capacidad_max,
            telefono: this.$route.params.sitio.telefono,
            direccion: this.$route.params.sitio.direccion,
            celular: this.$route.params.sitio.celular,
            nombre_contacto: this.$route.params.sitio.nombre_contacto,
            tipo_sitio: {
              descripcion: this.$route.params.sitio.nTipoSitio,
              numeracion: this.$route.params.sitio.tipo_sitio,
            },
            geocerca_zona_id: this.$route.params.sitio.geocercas_zona,
          };
        }
        this.getOperacion();
        this.getpro();
      } else {
        this.metodo = "POST";
        this.form.estado = 2;
      }

      this.cargando = false;
    },

    getEstados() {
      axios.get("/api/lista/1").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    getTipoSitio() {
      axios.get("/api/lista/95").then((response) => {
        this.listasForms.tipoSitio = response.data;
      });
    },

    getOperacionesSitio() {
      axios({
        method: "GET",
        url: "/api/tif/sitios/operacionesSitio",
        params: this.operacion.bloque.id,
      }).then((response) => {
        this.listasForms.operacionesSitio = response.data;
      });
    },

    buscarBloques(search) {
      let me = this;
      me.listasForms.bloques = [];
      if (search != "") {
        var url = "api/admin/bloques/listaSearch?bloque=" + search;
        axios
          .get(url)
          .then(function(response) {
            me.listasForms.bloques = response.data;
          })
          .catch(function(e) {
            me.$swal({
              icon: "error",
              title: "Ocurrió un error: " + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    getpro() {
      axios({
        method: "GET",
        url: "/api/tif/sitios/operacionesSitioProducto",
        params: this.operacion.tif_operacion_id,
      }).then((response) => {
        this.listasForms.productos = response.data;
      });
    },

    getTipoOperacion() {
      axios.get("/api/lista/97").then((response) => {
        this.listasForms.tipoOperacion = response.data;
      });
    },

    getGeocercaZona() {
      axios({
        method: "GET",
        url: "/api/tif/sitios/geocercaZona",
        params: this.$route.params.sitio,
      }).then((response) => {
        this.listasForms.zona = response.data;
      });
    },

    getOperacion() {
      axios({
        method: "GET",
        url: "/api/tif/sitios/operations",
        params: this.$route.params.sitio,
      }).then((response) => {
        this.listasForms.operations = response.data;
      });
    },

    validarOperacion() {
      this.cargando = true;
      axios({
        method: "GET",
        url: "/api/tif/sitios/validarOperacion",
        params: this.operacion,
      }).then((response) => {
        this.validar = response.data.msg;
        this.cargando = false;
        this.operacionesS();
      });
    },

    operacionesS() {
      this.cargando = true;
      if (this.validar == "si") {
        this.$swal({
          icon: "error",
          title: `Ya existe una operacion con las mismas especificaciones para este sitio`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.cargando = false;
      } else {
        this.operacion.tif_sitio_id = this.$route.params.sitio.id;
        axios({
          method: "POST",
          url: "/api/tif/sitios/saveOperacion",
          data: this.operacion,
        })
          .then((response) => {
            this.getOperacion();

            this.$swal({
              icon: "success",
              title: "Se asigno la operacion correctamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargando = false;
          })
          .catch((e) => {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: `Ha ocurrido un error: ${e.response.data.message}`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },
    // Acciones de formularios
    save() {
      if (!this.$v.form.$invalid) {
        this.cargando = true;
        // MOVIENDO LOS OBJETOS
        this.tipo_sitio = this.form.tipo_sitio;
        this.geocerca_zona_id = this.form.geocerca_zona_id;
        // ASIGNANDOLES LAS ID AL FORM PARA LA INSERCION
        this.form.tipo_sitio = this.form.tipo_sitio.numeracion;
        this.form.geocerca_zona_id = this.form.geocerca_zona_id.id;

        axios({
          method: this.metodo,
          url: "/api/tif/sitios/save",
          data: this.form,
        })
          .then((response) => {
            this.$route.params.sitio = response.data;
            this.$route.params.accion = "Editar";
            this.getIndex();
            this.$swal({
              icon: "success",
              title: "Se actualizó el sitio correctamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargando = false;
          })
          .catch((e) => {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: `Ha ocurrido un error: ${e.response.data.message}`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    back() {
      return this.$router.replace("/tif/Sitios");
    },

    destroy(id) {
      this.$swal({
        title: "Esta seguro de eliminar esta operacion del sitio?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        // <--
        if (result.value) {
          this.cargando = true;
          axios.delete("/api/tif/sitios/destroySitio/" + id).then(() => {
            this.getOperacion();
            this.$swal({
              icon: "success",
              title: "Se elimino la seccion de manera exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });

            this.cargando = false;
          });
        }
      });
    },
  },

  async mounted() {
    this.getIndex();
    this.getEstados();
    this.getTipoSitio();
    this.getGeocercaZona();
    this.getTipoOperacion();
    this.getOperacion();

    this.cargando = false;
    // this.getTipoVehiculo();
    // this.getTransportadoras();
    // this.getTurnos();
  },
};
</script>
